<template>
  <div>
    <CSpinner v-if="$apollo.queries.accountOwner.loading" color="info"/>
    <CAlert
        v-if="error"
        color="danger"
        closeButton
        @update:show="removeError">
      {{error}}
    </CAlert>
    <DeleteModal
        entity="account-owner"
        :name="itemName"
        :nid="accountOwner.nid"
        :show.sync="showDeleteItemModal"
        v-on:delete="deleteItem"
        v-on:cancel-delete="toggleDeleteItemModal"
    />
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol class="text-left" sm="2">
            <DetailsButtonGroup
                v-on:save="saveItem"
                v-on:cancel="resetItem"
                v-on:delete-prompt="toggleDeleteItemModal"
                v-on:close="$router.push({path: '/account-owners/account-owners'})"
            />
          </CCol>
          <CCol sm="8">
            <h2 data-sel="txt-title">{{itemName}}</h2>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <AccountOwnerForm
            v-bind:accountOwnerIn="accountOwnerEdited"
            v-on:update-item="updateItem"
            v-on:error="catchError"
        />
      </CCardBody>
      <CCardFooter
          class="text-left">
        <DetailsButtonGroup
            v-on:save="saveItem"
            v-on:cancel="resetItem"
            v-on:delete-prompt="toggleDeleteItemModal"
            v-on:close="$router.push({ path: '/account-owners/account-owners'})"
          />
      </CCardFooter>
    </CCard>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DetailsButtonGroup from "@/components/common/DetailsButtonGroup";
import DeleteModal from "@/components/common/DeleteModal";
import AccountOwnerForm from "@/apps/account_owners/components/AccountOwnerForm";

const queryAccountOwner = gql`
query accountOwner($nid: ID!) {
  accountOwner(nid: $nid) {
    nid
    email
    phone
    firstName
    lastName
    title
    avatar{
      hrefOrig
    }
  }
}
`
const updateAccountOwner = gql`
  mutation updateAcountOwner($nid: ID!, $accountOwner: AccountOwnerInput!){
    updateAccountOwner(nid: $nid, accountOwner: $accountOwner){
        nid
    }
  }
`
const deleteAccountOwner = gql`
  mutation deleteAcountOwner($nid: ID!){
    deleteAccountOwner(nid: $nid)
  }
`
const clonedeep = require('lodash.clonedeep')

export default {
  name: "AccountOwner",
  components: {
    DetailsButtonGroup,
    DeleteModal,
    AccountOwnerForm
  },
  data() {
    return {
      error: null,
      showDeleteItemModal: false,
      accountOwner: {},
      accountOwnerEdited: {},
      accountOwnerSave: {}
    }
  },
  methods: {
    removeError(hide){
      if(!hide){
        this.error = null
      }
    },
    catchError(event){
      this.error = event
    },
    updateItem(data){
      this.accountOwnerSave = data
    },
    saveItem(){
      const {nid, __typename, ...updatedObj} = this.accountOwnerSave;
      this.$apollo.mutate({
        mutation: updateAccountOwner,
        variables: {
          nid: nid,
          accountOwner: updatedObj
        }
      })
      .then(res => {
        this.$emit('save-item', this.accountOwnerEdited);
        this.$store.commit('set', ['addModalShow', false]);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/account-owners/account-owners'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    },
    toggleDeleteItemModal(){
      this.showDeleteItemModal = !this.showDeleteItemModal;
    },
    resetItem(){
      this.accountOwnerEdited = clonedeep(this.$apolloData.data.accountOwner);
    },
    deleteItem(){
      this.$apollo.mutate({
        mutation: deleteAccountOwner,
        variables: {
          nid: this.accountOwner.nid
        }
      })
      .then(res => {
        this.$emit('delete-account-owner', this.accountOwner.nid);
        this.$store.commit('set', ['refetchData', true]);
        this.$router.push({ path: '/account-owners/account-owners'});
      })
      .catch(error => {
        console.error(error);
        this.error = error.message;
      })
    }
  },
  computed: {
    allowEdit() {
      return this.$store.state.allowEdit
    },
    itemName(){
      return `${this.accountOwnerEdited.firstName} ${this.accountOwnerEdited.lastName}`
    }
  },
  apollo: {
    accountOwner: {
      query: queryAccountOwner,
      error(error) {
        this.error = error.message;
      },
      variables() {
        return {
          nid: this.$route.params.nid
        }
      },
      result ({ data, loading, networkStatus }) {
        this.resetItem();
      },
      fetchPolicy: "cache-and-network"
    }
  }
}
</script>

<style scoped>

</style>
